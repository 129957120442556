import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {
  DocumentFragment,
  Fragment,
  FragmentType,
  InternalReferenceType,
  SectionFragment,
  SectionType,
} from 'app/fragment/types';
import {InternalDocumentReferenceFragment} from 'app/fragment/types/reference/internal-document-reference-fragment';
import {SectionGroupFragment} from 'app/fragment/types/section-group-fragment';
import {WsrReferenceSectionResolver} from 'app/routing/resolvers/wsr-reference-section-resolver.service';
import {DocumentService} from 'app/services/document.service';
import {FragmentService} from 'app/services/fragment.service';
import {InternalReferenceService} from 'app/services/internal-reference.service';
import {ReferenceUtils} from 'app/services/references/reference-utils/reference-utils';
import {
  SectionDisplayReference,
  SectionDisplayReferenceUtils,
} from 'app/services/references/reference-utils/section-display-reference-utils';
import {Callback} from 'app/utils/typedefs';
import {CurrentView} from 'app/view/current-view';
import {ViewService} from 'app/view/view.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-wsr-reference-section',
  templateUrl: './wsr-reference-section.component.html',
  styleUrls: ['./wsr-reference-section.component.scss'],
})
export class WsrReferenceSectionComponent implements OnInit, OnDestroy {
  public readonly introductionContent: string = `
    The following Works Specific Requirements (WSR) documents are references for this document and shall be used when defining works specific requirements that, in conjunction with the Specification for Highway Works, supplementary and alternative clauses, form the contract specification.
    Country specific versions of each WSR are available. Users are responsible for applying all appropriate documents applicable to their contract.
  `;
  public readonly noReferencesContent: string =
    'There are no Works Specific Requirements (WSR) that are references for this document.';

  private _document: DocumentFragment;
  private _referencesNeedingUpdate: InternalDocumentReferenceFragment[] = [];
  private _subscriptions: Subscription[] = [];

  public readonly tooltipDelay = environment.tooltipDelay;

  public sectionTitle: string = WsrReferenceSectionResolver.WSR_REFERENCE_SECTION_TITLE;
  public sectionIndex: string;
  public currentView: CurrentView;
  public wsrReferences: SectionDisplayReference[];

  constructor(
    private _documentService: DocumentService,
    private _fragmentService: FragmentService,
    private _internalReferenceService: InternalReferenceService,
    private _viewService: ViewService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this._documentService.onSelection((document: DocumentFragment) => {
        this._document = document;
        this._getWsrReferences();
      })
    );

    this._createFragmentServiceListeners(this._getWsrReferences, this._internalDocumentRefFragmentPredicate);
    this._createFragmentServiceListeners(this._calculateSectionIndex, this._sectionFragmentPredicate);

    this._document = this._documentService.getSelected();

    this._getWsrReferences();
    this._calculateSectionIndex(false);

    this.currentView = this._viewService.getCurrentView();

    this._internalReferenceService
      .getReferencesToUpdate(this._document.getNormReferenceSection().documentId, [InternalReferenceType.WSR_REFERENCE])
      .then((referencesToUpdate: InternalDocumentReferenceFragment[]) => {
        this._referencesNeedingUpdate = referencesToUpdate;
      });
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private _createFragmentServiceListeners(
    callback: Callback<void | boolean>,
    predicate: (fragment: Fragment) => boolean
  ): void {
    this._subscriptions.push(
      this._fragmentService.onUpdate((_fragment: Fragment) => {
        callback.call(this);
      }, predicate.bind(this)),
      this._fragmentService.onCreate((_fragment: Fragment) => {
        callback.call(this);
      }, predicate.bind(this)),
      this._fragmentService.onDelete((_fragment: Fragment) => {
        callback.call(this);
      }, predicate.bind(this))
    );
  }

  private _internalDocumentRefFragmentPredicate(fragment: Fragment): boolean {
    return (
      this._document.id.equals(fragment.documentId) &&
      fragment.is(FragmentType.INTERNAL_DOCUMENT_REFERENCE) &&
      (fragment as InternalDocumentReferenceFragment).internalReferenceType === InternalReferenceType.WSR_REFERENCE
    );
  }

  private _sectionFragmentPredicate(fragment: Fragment): boolean {
    return (
      this._document.id.equals(fragment.documentId) && fragment.is(FragmentType.SECTION, FragmentType.SECTION_GROUP)
    );
  }

  /**
   * Filters the children of the normative reference section's clause looking for InternalDocumentReferenceFragments of WSR_REFERENCE type.
   * These references are then looped and mapped from InternalDocumentReferenceFragments into SectionDisplayReferences.
   */
  private _getWsrReferences(): void {
    this.wsrReferences = [];
    this._document
      .getNormReferenceSection()
      .children[0].children.filter(
        (ref) =>
          ref.is(FragmentType.INTERNAL_DOCUMENT_REFERENCE) &&
          (ref as InternalDocumentReferenceFragment).internalReferenceType === InternalReferenceType.WSR_REFERENCE
      )
      .map((ref) => ref as InternalDocumentReferenceFragment)
      .sort(this._sortWsrReferences)
      .forEach((ref) =>
        this.wsrReferences.push(SectionDisplayReferenceUtils.mapWsrReferenceToSectionDisplayReference(ref))
      );
    this._cdr.markForCheck();
  }

  /**
   * Sorts WSR_REFERENCES based on their WSR code. It splits it into the 3 components: the discipline + lifecycle, the document number, the topic number.
   * Based on these, it will order them alphabetically or numerically, depending on whether we're comparing strings or numbers.
   * @param a current WSR_REFERENCE
   * @param b next WSR_REFERENCE
   * @returns number representing the ordering required
   */
  private _sortWsrReferences(a: InternalDocumentReferenceFragment, b: InternalDocumentReferenceFragment): number {
    const pattern: RegExp = new RegExp('([A-Z]{2}) ([0-9]{3,4})/WSR/([0-9]{3})');

    const aValues = pattern.exec(a.wsrCode);
    const bValues = pattern.exec(b.wsrCode);

    if (aValues && bValues) {
      for (let idx = 1; idx <= 3; idx++) {
        const aValue = idx === 1 ? aValues[idx] : parseInt(aValues[idx], 10);
        const bValue = idx === 1 ? bValues[idx] : parseInt(bValues[idx], 10);

        if (aValue > bValue) {
          return 1;
        } else if (aValue < bValue) {
          return -1;
        }
      }
    }

    return 0;
  }

  private _calculateSectionIndex(refreshDocument: boolean = true): void {
    if (refreshDocument) {
      this._document = this._documentService.getSelected();
    }
    const sectionIndex =
      this._document.children.filter((child) => {
        if (child.type === FragmentType.SECTION_GROUP) {
          return !(child as SectionGroupFragment).deleted;
        }
        return (
          !(child as SectionFragment).deleted &&
          (child as SectionFragment).sectionType !== SectionType.DOCUMENT_INFORMATION
        );
      }).length + 1;

    this.sectionIndex = `${sectionIndex}.`;
  }

  /* Used in the component HTML. Generates the combined info/error message that should be shown to the user alongside
    the reference, or null if the reference is valid and up to date. Note that this can include
    both global and internal reference issues. */
  public getErrorMessageForReference(sectionReference: SectionDisplayReference): string {
    return ReferenceUtils.getErrorMessageForReference(
      sectionReference,
      this._doesReferenceNeedUpdatePredicate.bind(this)
    );
  }

  /**
   * True if the provided reference is out of date compared to
   * the most recent published version of the target section (or live version, if none published).
   * Applicable only to InternalDocumentReferenceFragments.
   */
  private _doesReferenceNeedUpdatePredicate(sectionReference: SectionDisplayReference): boolean {
    return (
      !!sectionReference.internalReferenceProperties &&
      !!this._referencesNeedingUpdate.filter((ref: InternalDocumentReferenceFragment) =>
        ref.targetSectionId.equals(sectionReference.targetId)
      ).length
    );
  }
}
