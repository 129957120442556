import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {DocumentFragment} from 'app/fragment/types';
import {Breadcrumb, VersionTag} from 'app/interfaces';
import {DocumentService} from 'app/services/document.service';
import {SectionService} from 'app/services/section.service';
import {CurrentView, ViewMode} from 'app/view/current-view';
import {CurrentLocation, ViewService} from 'app/view/view.service';

@Injectable({
  providedIn: 'root',
})
export class WsrReferenceSectionResolver implements Resolve<void> {
  public static WSR_REFERENCE_SECTION_TITLE = 'WSR references';

  constructor(
    private _documentService: DocumentService,
    private _sectionService: SectionService,
    private _viewService: ViewService
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    const currentView: CurrentView = this._viewService.getCurrentView();
    this._viewService.setLocation(CurrentLocation.PAD);
    if (!currentView.isLive() && !currentView.isHistorical() && !currentView.isChangelogMarkup()) {
      this._viewService.setCurrentView(
        currentView.isHistoricalDocument() ? ViewMode.HISTORICAL : ViewMode.LIVE,
        currentView.versionTag
      );
    }

    const document: DocumentFragment = this._documentService.getSelected();
    this._sectionService.setSelected(null);

    const breadcrumbs: Breadcrumb[] = this._generateBreadcrumbs(document, route, currentView.versionTag);
    route.data = {breadcrumbs: breadcrumbs};
  }

  private _generateBreadcrumbs(
    document: DocumentFragment,
    route: ActivatedRouteSnapshot,
    versionTag: VersionTag
  ): Breadcrumb[] {
    const urlSegments: Map<string, string> = new Map();

    // If document title and section title overlay the path can become corrupt.
    // We add an invisible space to the section title when the document title
    // and section title are the same.
    const documentTitle = document.title;
    const sectionTitle =
      document.title === WsrReferenceSectionResolver.WSR_REFERENCE_SECTION_TITLE
        ? WsrReferenceSectionResolver.WSR_REFERENCE_SECTION_TITLE + ' '
        : WsrReferenceSectionResolver.WSR_REFERENCE_SECTION_TITLE;

    if (this._isTopLevelRoute(route)) {
      urlSegments.set('Documents', 'documents');
      urlSegments.set(documentTitle, document.id.value);
      if (versionTag) {
        urlSegments.set('Versions', 'versions');
        urlSegments.set(versionTag.name, versionTag.versionId.value);
      }
    }

    urlSegments.set(sectionTitle, `wsr-references`);

    const breadcrumbs: Breadcrumb[] = [];
    let link: string = this._isTopLevelRoute(route) ? '' : route.parent.url.map((seg) => seg.path).join('/');
    urlSegments.forEach((urlSegment: string, title: string) => {
      link += '/' + urlSegment;
      breadcrumbs.push({title: title, link: link});
    });

    return breadcrumbs;
  }

  /**
   * Determines whether this is a child of a document-level route or not.
   *
   * @param route {ActivatedRouteSnapshot} The route.
   * @returns     {boolean}                True if this is not a child route.
   */
  private _isTopLevelRoute(route: ActivatedRouteSnapshot): boolean {
    return route.paramMap.has('document_id');
  }
}
