import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {WsrReferenceSectionComponent} from './wsr-reference-section.component';

@NgModule({
  imports: [CommonModule, MatTooltipModule, MatIconModule],
  declarations: [WsrReferenceSectionComponent],
  exports: [WsrReferenceSectionComponent],
})
export class WsrReferenceSectionModule {}
