<mat-list-item [class.active]="selected">
  <a
    class="section-like"
    color="primary"
    id="wsr-references"
    [routerLink]="wsrReferenceSectionRoute"
    aria-label="View WSR references"
  >
    <div class="section-details">
      <div
        class="left-hand-buffer"
        *ngIf="CarsAction.AUTHOR_DOCUMENT | carsEnabled : document.documentId | async"
      ></div>
      <span id="section-index" matPrefix>
        {{ sectionIndex }}
      </span>
      <span id="section-title">
        {{ sectionTitle }}
      </span>
    </div>
  </a>

  <cars-alt-accessibility
    class="wsr-references"
    [altProperties]="{initialKey: 'q', key: 'c', condition: true}"
    (onSelect)="focusOverview()"
  ></cars-alt-accessibility>
</mat-list-item>
