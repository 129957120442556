import {OverlayKeyboardDispatcher} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule, Provider} from '@angular/core';
import {MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS} from '@angular/material/button-toggle';
import {MatDialog} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  MatTooltipDefaultOptions,
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY,
} from '@angular/material/tooltip';
import {BrowserModule, EventManager} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouteReuseStrategy} from '@angular/router';
import {CarsEventManager} from 'app/cars-event-manager';
import {CarsHttpInterceptor} from 'app/cars-http-interceptor';
import {CarsOverlayKeyboardDispatcher} from 'app/cars-overlay-keyboard-dispatcher';
import {OfflineModule} from 'app/offline/offline.module';
import {UserRepository} from 'app/offline/repository';
import {AuthenticationProvider} from 'app/services/user/authentication-provider';
import {AuthenticationProviderFactory} from 'app/services/user/authentication-provider-factory';
import {environment} from 'environments/environment';
import {AccessibilityManagerModule} from './accessibility/accessibility-manager/accessibility-manager.module';
import {AdminModule} from './admin/admin.module';
import {CarsRoutingModule} from './cars-routing.module';
import {CarsComponent} from './cars.component';
import {ChangelogModule} from './changelog/changelog.module';
import {ClauseChangeSummaryModule} from './clause-change-summary/clause-change-summary.module';
import {ContainerModule} from './container/container.module';
import {CustomRouteReuseStrategy} from './custom-route-reuse-strategy';
import {DashboardModule} from './dashboard/dashboard.module';
import {ErrorDialogModule} from './dialog/error-dialog/error-dialog.module';
import {AssociatedDocumentInformationModalModule} from './document-overview/document-information/associated-document-information/associated-document-information-modal/associated-document-information-modal.module';
import {DocumentOverviewModule} from './document-overview/document-overview.module';
import {DocumentVersionsModule} from './document-versions/document-versions.module';
import {DocumentsModule} from './documents/documents.module';
import {CarsErrorHandlingModule} from './error-handling/cars-error-handling.module';
import {FragmentModule} from './fragment/fragment.module';
import {HeaderModule} from './header/header.module';
import {PageNotFoundModule} from './page-not-found/page-not-found.module';
import {PermissionHandlersModule} from './permissions/handlers/permission-handlers.module';
import {PrintModule} from './print/print.module';
import {SectionContentModule} from './section-content/section-content.module';
import {WsrReferenceSectionModule} from './section-content/section-wsr-reference/wsr-reference-section.module';
import {SuggestionsHovertipModule} from './spell-checker/suggestions-hovertip/suggestions-hovertip.module';
import {TableUpdaterModule} from './widgets/table-updater/table-updater.module';
import {WsrSubjectTopicModule} from './wsr-subject-topic/wsr-subject-topic.module';

const CARS_TOOLTIP_DEFAULT_OPTIONS: MatTooltipDefaultOptions = {
  ...MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY(),
  disableTooltipInteractivity: localStorage.getItem('cars-show-accessibility-focus') !== 'true',
  showDelay: environment.tooltipDelay,
};

const BOOTSTRAP = [CarsComponent];

const PROVIDERS: Provider[] = [
  {provide: HTTP_INTERCEPTORS, useClass: CarsHttpInterceptor, multi: true},
  {
    provide: AuthenticationProvider,
    useFactory: AuthenticationProviderFactory.factory,
    deps: [HttpClient, MatDialog, MatSnackBar, UserRepository],
  },
  {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
  {provide: EventManager, useClass: CarsEventManager},
  {
    provide: OverlayKeyboardDispatcher,
    useClass: CarsOverlayKeyboardDispatcher,
  },
  {provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, useValue: {appearance: 'legacy'}},
  {
    provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
    useValue: CARS_TOOLTIP_DEFAULT_OPTIONS,
  },
];

@NgModule({
  declarations: [CarsComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CarsRoutingModule,
    CommonModule,
    HttpClientModule,
    HeaderModule,
    MatProgressSpinnerModule,
    AccessibilityManagerModule,
    CarsErrorHandlingModule.forRoot(),
    FragmentModule,
    SuggestionsHovertipModule,
    OfflineModule,
    ErrorDialogModule,
    DocumentsModule,
    DashboardModule,
    AdminModule,
    DocumentVersionsModule,
    SectionContentModule,
    ChangelogModule,
    DocumentOverviewModule,
    ContainerModule,
    HeaderModule,
    PageNotFoundModule,
    TableUpdaterModule,
    PrintModule,
    PermissionHandlersModule,
    ClauseChangeSummaryModule,
    WsrSubjectTopicModule,
    WsrReferenceSectionModule,
    AssociatedDocumentInformationModalModule,
  ],
  providers: PROVIDERS,
  bootstrap: BOOTSTRAP,
})
export class CarsModule {}
