import {Injectable} from '@angular/core';
import {CacheManager} from 'app/fragment/cache-manager';
import {VersionTag} from 'app/interfaces';
import {GlobalRole} from 'app/services/user/authentication-provider';
import {DisabledUserService} from 'app/services/user/disabled-user.service';
import {RoleService} from 'app/services/user/role.service';
import {UserService} from 'app/services/user/user.service';
import {Discussion} from 'app/sidebar/discussions/discussions';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/internal/operators/distinctUntilChanged';
import {DocumentRole} from '../documents/document-data';
import {Callback} from '../utils/typedefs';
import {CurrentView, ViewMode} from './current-view';

export enum CurrentLocation {
  UNKNOWN = 'UNKNOWN',
  DOCUMENTS_TABLE = 'DOCUMENTS_TABLE',
  VERSIONS_TABLE = 'VERSIONS_TABLE',
  DOCUMENT_OVERVIEW = 'DOCUMENT_OVERVIEW',
  PAD = 'PAD',
  CHANGELOG = 'CHANGELOG',
  BACKGROUND = 'BACKGROUND',
  COMMENTS = 'COMMENTS',
  PRINT_PREVIEW = 'PRINT_PREVIEW',
  COMPARE = 'COMPARE',
  LINK_TABLE = 'LINK_TABLE',
  CHANGE_SUMMARY = 'CHANGE_SUMMARY',
  WSR_SUBJECT_TOPIC = 'WSR_SUBJECT_TOPIC',
}

@Injectable({
  providedIn: 'root',
})
export class ViewService {
  private currentViewSubject: BehaviorSubject<CurrentView> = new BehaviorSubject<CurrentView>(
    CurrentView.createInitView()
  );
  private locationSubject: BehaviorSubject<CurrentLocation> = new BehaviorSubject<CurrentLocation>(
    CurrentLocation.UNKNOWN
  );

  public canAuthor: boolean;

  constructor(
    private userService: UserService,
    private roleService: RoleService,
    private disabledUserService: DisabledUserService,
    private cacheManager: CacheManager
  ) {
    this.disabledUserService.onUserStatuses().subscribe((statuses: Record<string, boolean>) => {
      this.canAuthor = !(!!this.userService.getUser() && statuses[this.userService.getUser().id.value]);
      this.setCurrentView(this.currentViewSubject.value.viewMode, this.currentViewSubject.value.versionTag);
    });
  }

  public setLocation(location: CurrentLocation): void {
    this.locationSubject.next(location);
  }

  public getLocation(): CurrentLocation {
    return this.locationSubject.getValue();
  }

  public onLocationChange(callback: Callback<CurrentLocation>): Subscription {
    return this.locationSubject.pipe(distinctUntilChanged()).subscribe(callback);
  }

  /**
   * Sets the current view for a document.
   *
   * @param viewMode      {ViewMode}   ViewMode to set
   * @param versionTag    {VersionTag} Optional version tag; default to null
   * @param secondaryData {any}        Other data needed to set the current view.
   */
  public setCurrentView(viewMode: ViewMode, versionTag: VersionTag = null, secondaryData: any = null): void {
    this.roleService.getGlobalRoles().then((globalRoles: GlobalRole[]) => {
      const userRole: DocumentRole = this.roleService.getUserRoleInDocument();
      const newCurrentView: CurrentView = new CurrentView(
        viewMode,
        userRole,
        versionTag,
        this.canAuthor,
        secondaryData,
        globalRoles
      );
      this.currentViewSubject.next(newCurrentView);
      this.updateCacheManager();
    });
  }

  private updateCacheManager() {
    const view: CurrentView = this.currentViewSubject.value;
    if (view && view.versionTag) {
      this.cacheManager.time = view.versionTag.createdAt;
    } else {
      this.cacheManager.time = null;
    }
  }

  /**
   * @returns the currentViewSubject as an observable.
   */
  public getCurrentViewObsStream(): Observable<CurrentView> {
    return this.currentViewSubject.asObservable();
  }

  /**
   * Subscribes to changes in the currentViewSubject. Passing the given callback to the new value.
   *
   * @param callback {Callback<CurrentView>} The callback to pass the new current view to
   */
  public onCurrentViewChange(callback: Callback<CurrentView>): Subscription {
    return this.currentViewSubject.subscribe(callback);
  }

  /**
   * @returns the current view from the subject
   */
  public getCurrentView(): CurrentView {
    return this.currentViewSubject.getValue();
  }

  /**
   * Determines whether this discussion applies to the current view.
   *
   * @param discussion  {Discussion} The discussion to check
   * @returns           {boolean}    If the discussion is applicable to the current view
   */
  public appliesToDiscussion(discussion: Discussion): boolean {
    const currentView: CurrentView = this.getCurrentView();
    const versionTag: VersionTag = currentView.versionTag;

    if (currentView.isHistorical()) {
      return this._appliesAgainstVersion(discussion);
    } else if (currentView.isComments()) {
      if (versionTag) {
        return this._appliesAgainstVersion(discussion);
      } else {
        return true;
      }
    } else if (currentView.isCompare()) {
      const appliesToVersion: boolean = versionTag ? this._appliesAgainstVersion(discussion) : true;
      const appliesToOtherVersion: boolean = currentView.otherVersionTag
        ? this._appliesAgainstVersion(discussion, true)
        : true;
      return appliesToVersion || appliesToOtherVersion;
    }
    return true;
  }

  /**
   * Determines whether this discussion applies to the current version.
   *
   * @param discussion      {Discussion} The discussion to check.
   * @param otherVersionTag {boolean}    Whether to use the secondary version tag.
   * @returns               {boolean}    True if applicable to current version.
   */
  private _appliesAgainstVersion(discussion: Discussion, otherVersionTag: boolean = false): boolean {
    const currentView: CurrentView = this.getCurrentView();
    const versionTag: VersionTag = otherVersionTag ? currentView.otherVersionTag : currentView.versionTag;

    if (discussion.wasRaisedAgainst(versionTag.versionId)) {
      if (versionTag.userDiscussionsOnly === true && currentView.userIsAReviewer()) {
        return discussion.wasRaisedBy(this.userService.getUser().id);
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
