import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatListModule} from '@angular/material/list';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {DocumentOverviewSectionModule} from './document-overview-section/document-overview-section.module';
import {SectionCreatorModule} from './section-creator/section-creator.module';
import {SectionGroupListItemModule} from './section-group-list-item/section-group-list-item.module';
import {SectionListItemModule} from './section-list-item/section-list-item.module';
import {SectionListComponent} from './section-list.component';
import {WsrReferenceSectionListItemModule} from './wsr-reference-section-list-item/wsr-reference-section-list-item.module';

@NgModule({
  imports: [
    CommonModule,
    MatListModule,
    DocumentOverviewSectionModule,
    WsrReferenceSectionListItemModule,
    SectionListItemModule,
    SectionGroupListItemModule,
    SectionCreatorModule,
    CarsPermissionPipesModule,
  ],
  declarations: [SectionListComponent],
  exports: [SectionListComponent],
})
export class SectionListModule {}
