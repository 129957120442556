import {Serialisable} from '../utils/serialisable';
import {UUID} from '../utils/uuid';
import {Administration} from './administrations';
import {LinkedDocuments} from './linked-documents';

/**
 * An enumeration of different user roles for a document.
 */
export enum DocumentRole {
  LEAD_AUTHOR = 'lead_author',
  AUTHOR = 'author',
  REVIEWER = 'reviewer',
  PEER_REVIEWER = 'peer_reviewer',
  OWNER = 'owner',
}

export type WorkflowStatus = SFPWorkflowStatus | SRPWorkflowStatus;

/**
 * An enumeration of workflow states, whose string values are the states'
 * display names.
 *
 * The string values of this enum match the workflow states returned from JIRA.
 *
 * This is the set of enums which were present in the SFP project but not in the SRP project which replaced it.
 * Documents still exist with these workflow statuses, but these can be considered deprecated.
 */
export enum SFPWorkflowStatus {
  BUSINESS_NEED = 'Business Need',
  IN_QMR1_APPROVAL = 'In QMR1 Approval',
  IN_SFP_BACKLOG = 'In SFP Backlog',
  DRAFTING_BLOCKED = 'Drafting Blocked',
  TPB_REVIEW = 'In TPB Review',
  AWAITING_DIVISIONAL_DIRECTOR_SIGNOFF = 'Awaiting Divisional Director Signoff',
  IN_QMR2_APPROVAL = 'In QMR2 Approval',
  AWAITING_CHE_SIGNOFF = 'Awaiting CHE Signoff',
  AWAITING_DEVOLVED_ADMIN_SIGNOFF = 'Awaiting Devolved Admin Signoff',
  APPROVAL_COMPLETE = 'Approval Complete',
  FAILED_SIGNOFF = 'Failed Signoff',
  AWAITING_EU_NOTIFICATION = 'Awaiting EU Notification',
  IN_EU_STANDSTILL = 'In EU Standstill',
  ADDRESSING_EU_COMMENTS = 'Adressing EU Comments',
  READY_FOR_PUBLISHING = 'Ready For Publishing',
  IN_PUBLISHING_REVIEW = 'In Publishing Review',
}

/**
 * An enumeration of workflow states, whose string values are the states'
 * display names.
 *
 * The string values of this enum match the workflow states returned from JIRA.
 */
export enum SRPWorkflowStatus {
  NO_JIRA_LINK = 'No JIRA Link',
  NEW = 'New',
  DRAFT_SUBMITTED_TO_TSC_CHAIR_FOR_REVIEW = 'Draft submitted to TSC chair for review',
  SUBMITTED_FOR_ADDITION_TO_SRP = 'Submitted for addition to SRP',
  IN_DRAFTING = 'In Drafting',
  IN_CONFIRMATION_OF_CATEGORY_OF_CHANGE_BY_TSC_CHAIR = 'In confirmation of category of change by TSC chair',
  IN_CONFIRMATION_OF_CATEGORY_OF_CHANGE_BY_TAGG = 'In confirmation of category of change by TAGG',
  MOVED_TO_CAT_A_WORKFLOW = 'Moved to Cat A workflow',
  CATEGORY_OF_CHANGE_CONFIRMED = 'Category of change confirmed',
  MOVED_TO_CAT_B_C_WORKFLOW = 'Moved to Cat B/C Workflow',
  MOVED_TO_CAT_D_WORKFLOW = 'Moved to Cat D Workflow',
  IN_TSC_REVIEW = 'In TSC Review',
  RETURNED_TO_TECH_AUTHOR_WITH_COMMENTS = 'Returned to tech author with comments',
  IN_APPROVAL_BY_DD = 'In approval by DD',
  IN_APPROVAL_WITH_OO_HEADS_OF_STANDARDS = 'In approval with OO Heads of Standards',
  IN_PREPARATION_FOR_AUTHORISATION = 'In preparation for authorisation',
  FAILED_AUTHORISATION = 'Failed Authorisation',
  IN_CONFIRMATION_OF_NOTIFICATION = 'In confirmation of notification',
  AWAITING_EC_NOTIFICATION = 'Awaiting EC notification',
  IN_EC_STANDSTILL_3_MONTHS = 'In EC standstill (3 months)',
  EC_SUBMISSION_FAILURE_LEGAL_ACTIONS = 'EC submission failure - legal actions',
  IN_EC_STANDSTILL_FOR_DETAILED_OPINIONS_6_MONTHS_OVERALL = 'In EC standstill for detailed opinions (6 months overall)',
  ADDRESSING_EC_COMMENTS = 'Addressing EC Comments',
  IN_SRP_BACKLOG = 'In SRP Backlog',
  READY_FOR_DRAFTING = 'Ready For Drafting',
  IN_PUBLISHING = 'In Publishing',
  PUBLICATION_BLOCKED = 'Publication blocked',
  PUBLISHED = 'Published',
  IN_CHE_CRE_AUTHORISATION = 'In CHE/CRE authorisation',
  IN_PREPARATION_FOR_APPROVAL = 'In preparation for approval',
}

/**
 * A class representing the user data and metadata associated with a
 * DocumentFragment.
 *
 * @field owner          {UUID}             The UUID of the owning user
 * @field leadAuthor     {UUID}             The UUID of the lead author
 * @field authors        {UUID}             The UUIDs of all authors
 * @field reviewers      {UUID}             The UUIDs of all reviewers
 * @field peerReviewers  {UUID}             The UUIDs of all peer reviewers
 * @field administration {Administration}   The devolved administration
 * @field workflowStatus {WorkflowStatus}   The current workflow status
 * @field metadata       {Object}           An object of arbitrary metadata
 * @field naaRelationships {LinkedDocuments}    An object detailing the relationship between a core document and linked NAA documents
 */
export class DocumentData implements Serialisable {
  public owner: UUID;
  public leadAuthor: UUID;
  public authors: UUID[];
  public reviewers: UUID[];
  public peerReviewers: UUID[];
  public administration: Administration;
  public workflowStatus: WorkflowStatus;
  public metadata: object;
  public coreDocument: UUID;
  public naaRelationships: LinkedDocuments;

  /**
   * Deserialise an instance of DocumentData from a JSON object.
   *
   * @param json {any}            The JSON
   * @returns    {DocumentData}   The document metadata
   */
  public static deserialise(json: any): DocumentData {
    const authors: UUID[] = json.authors.map((id: string) => UUID.orThrow(id));
    const reviewers: UUID[] = json.reviewers.map((id: string) => UUID.orThrow(id));
    const peers: UUID[] = json.peerReviewers.map((id: string) => UUID.orThrow(id));

    return new DocumentData(
      UUID.orNull(json.owner),
      UUID.orNull(json.leadAuthor),
      authors,
      reviewers,
      peers,
      Administration[json.administration as string],
      json.workflowStatus,
      json.metadata,
      LinkedDocuments.deserialise(json.naaRelationships),
      UUID.orNull(json.coreDocument)
    );
  }

  /**
   * Create an empty DocumentData object with the given owner.
   *
   * @param owner           {UUID}             The document owner's UUID
   * @param administration  {Administration}   The document administration
   * @param status          {WorkflowStatus}   The workflow status, default NO_JIRA_LINK
   * @returns               {DocumentData}     The new data object
   */
  public static empty(
    owner: UUID,
    administration: Administration = null,
    status: WorkflowStatus = SRPWorkflowStatus.NO_JIRA_LINK
  ): DocumentData {
    return new DocumentData(owner, null, [], [], [], administration, status, {}, null, null);
  }

  constructor(
    owner: UUID,
    leadAuthor: UUID,
    authors: UUID[],
    reviewers: UUID[],
    peerReviewers: UUID[],
    administration: Administration = null,
    workflowStatus: WorkflowStatus,
    metadata: object,
    naaRelationships: LinkedDocuments,
    coreDocument: UUID
  ) {
    this.owner = owner;
    this.leadAuthor = leadAuthor;
    this.authors = authors || [];
    this.reviewers = reviewers || [];
    this.peerReviewers = peerReviewers || [];
    this.administration = administration;
    this.workflowStatus = workflowStatus;
    this.metadata = metadata || {};
    this.naaRelationships = !!naaRelationships ? naaRelationships : new LinkedDocuments();
    this.coreDocument = coreDocument;
  }

  /**
   * @inheritdoc
   */
  public serialise(): any {
    return {
      owner: this.owner ? this.owner.value : null,
      leadAuthor: this.leadAuthor ? this.leadAuthor.value : null,
      authors: this.authors.map((id: UUID) => id.value),
      reviewers: this.reviewers.map((id: UUID) => id.value),
      peerReviewers: this.peerReviewers.map((id: UUID) => id.value),
      workflowStatus: this.workflowStatus,
      administration: this.administration,
      metadata: this.metadata,
      naaRelationships: this.naaRelationships?.serialise(),
      coreDocument: this.coreDocument ? this.coreDocument.value : null,
    };
  }
}
