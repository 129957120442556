<div class="header-container">
  <span class="banner">Please note this section will only be exported as part of IfS outputs</span>
  <span *ngIf="currentView.versionTag" class="version-tag">Version: {{ currentView.versionTag?.name }}</span>
</div>
<div
  class="pad-container"
  [ngClass]="{
    'pad-max-height': currentView.isLive() || currentView.isExport()
  }"
>
  <div class="pad-padding pad-max-height">
    <div class="pad">
      <div class="content">
        <div class="title">
          <span class="index"> {{ sectionIndex }}</span>
          <div class="title-text">
            {{ sectionTitle }}
          </div>
        </div>
        <div class="centered">
          <ng-container *ngIf="!wsrReferences.length; else referenceContent">
            <span class="intro">
              {{ noReferencesContent }}
            </span>
          </ng-container>
          <ng-template #referenceContent>
            <span class="intro">
              {{ introductionContent }}
            </span>
            <table>
              <tr>
                <th class="reference-column">WSR Code</th>
                <th class="title-column">WSR Topic</th>
              </tr>
              <ng-container *ngFor="let reference of wsrReferences">
                <tr [class.red]="reference.internalReferenceProperties?.deleted">
                  <td class="reference-column">
                    <mat-icon
                      *ngIf="getErrorMessageForReference(reference) as toolTipText"
                      [matTooltip]="toolTipText"
                      matTooltipPosition="left"
                      [matTooltipShowDelay]="tooltipDelay"
                    >
                      error_outline
                    </mat-icon>
                    {{ reference.wsrCode }}
                  </td>
                  <td class="title-column">
                    {{ reference.formattedTitle }}
                  </td>
                </tr>
              </ng-container>
            </table>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
