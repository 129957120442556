import {Inject, Injectable, OnDestroy} from '@angular/core';
import {ChangelogService} from 'app/changelog/changelog.service';
import {PadType} from 'app/element-ref.service';
import {DocumentFragment} from 'app/fragment/types';
import {DocumentService} from 'app/services/document.service';
import {ConfigurationService} from 'app/suite-config/configuration.service';
import {UUID} from 'app/utils/uuid';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {mergeMap, shareReplay, skipWhile} from 'rxjs/operators';
import {HANDLERS_INJECTION_TOKEN} from './handlers/permission-handlers.module';
import {PermissionsHandler} from './handlers/permissions-handler';
import {PermissionASTNode} from './types/permission-ast-node';
import {CarsAction, PermissionInput} from './types/permissions';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService implements OnDestroy {
  private permissions: Map<CarsAction, PermissionASTNode>;

  private permissionsStreamMap: Map<CarsAction, Map<PadType, Observable<boolean>>> = new Map();

  private handlers: Map<PermissionInput, PermissionsHandler> = new Map();

  private ready: Subject<boolean> = new BehaviorSubject(false);

  // We should not have an entry in this record for PadType.NO_PAD
  private readonly _padTypeToDocumentId: Partial<Record<PadType, UUID>> = {
    [PadType.MAIN_EDITABLE]: null,
    [PadType.PUBLISHED_CHANGLOG]: null,
  };

  private subscriptions: Subscription[] = [];

  constructor(
    private configurationService: ConfigurationService,
    private documentService: DocumentService,
    private changelogService: ChangelogService,
    @Inject(HANDLERS_INJECTION_TOKEN) handlers: PermissionsHandler[]
  ) {
    handlers.forEach((handler: PermissionsHandler) => {
      this.register(handler);
    });
    this.configurationService.getPermissions().then((permissions: Map<CarsAction, PermissionASTNode>) => {
      this.permissions = permissions;
      this.ready.next(true);
    });

    this.subscriptions.push(
      this.documentService.getDocumentStream().subscribe((doc: DocumentFragment) => {
        this._padTypeToDocumentId[PadType.MAIN_EDITABLE] = !!doc ? doc.id : null;
      }),
      this.changelogService.getPublishedDocumentStream().subscribe((doc: DocumentFragment) => {
        this._padTypeToDocumentId[PadType.PUBLISHED_CHANGLOG] = !!doc ? doc.id : null;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.splice(0).forEach((s: Subscription) => s.unsubscribe());
  }

  private register(handler: PermissionsHandler): void {
    if (this.handlers.get(handler.getInputType()) === undefined) {
      this.handlers.set(handler.getInputType(), handler);
    } else {
      throw new Error(`Handler already registered for ${handler.getInputType()}`);
    }
  }

  public can(action: CarsAction, documentId: UUID): Observable<boolean> {
    const padType: PadType = this._getPadType(documentId);
    if (action === CarsAction.AUTHOR_DOCUMENT) {
      let go;
    }
    let obs: Observable<boolean> = this.permissionsStreamMap.get(action)?.get(padType);

    if (!obs) {
      obs = this.ready.pipe(
        skipWhile((ready) => !ready),
        mergeMap(() => {
          const node: PermissionASTNode = this.permissions.get(action);
          return node.can(this.handlers, padType);
        }),
        shareReplay(1)
      );

      if (!this.permissionsStreamMap.get(action)) {
        this.permissionsStreamMap.set(action, new Map());
      }

      this.permissionsStreamMap.get(action).set(padType, obs);
    }

    return obs;
  }

  private _getPadType(documentId: UUID): PadType {
    if (!documentId) {
      return PadType.NO_PAD;
    }

    return (
      Object.keys(this._padTypeToDocumentId)
        .map((key: string) => PadType[key])
        .find((padType: PadType) => documentId.equals(this._padTypeToDocumentId[padType])) ?? PadType.NO_PAD
    );
  }
}
