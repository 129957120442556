import {FragmentType} from 'app/fragment/types';
import {SectionDisplayReference} from 'app/services/references/reference-utils/section-display-reference-utils';
import {Predicate} from 'app/utils/typedefs';

export class ReferenceUtils {
  public static getErrorMessageForReference(
    sectionReference: SectionDisplayReference,
    updatePredicate: Predicate<SectionDisplayReference>
  ): string {
    let errorString: string = '';
    if (this._referenceWithdrawn(sectionReference)) {
      errorString += 'This is a withdrawn reference. ';
    }
    if (this._referenceDeleted(sectionReference, FragmentType.DOCUMENT_REFERENCE)) {
      errorString +=
        'This reference has been deleted from the library by TAGG, please replace with the correct reference. ';
    }
    if (updatePredicate(sectionReference)) {
      errorString +=
        'Updates have been made to sections within the referenced document that need to be reflected in section references. ' +
        'Please use the ‘Refresh section references’ functionality provided on the Document Information page. ';
    }
    if (this._referenceDeleted(sectionReference, FragmentType.INTERNAL_DOCUMENT_REFERENCE)) {
      errorString +=
        'One or more sections within the referenced document have been deleted and section references within this document need' +
        ' to be updated.  Please remove or update associated section references.';
    }

    return errorString?.length > 0 ? errorString : null;
  }

  /**
   * True if the provided reference has been withdrawn
   * and not deleted. Applicable only to DocumentReferenceFragments
   */
  private static _referenceWithdrawn(sectionReference: SectionDisplayReference): boolean {
    return (
      !!sectionReference.globalReferenceProperties &&
      !sectionReference.globalReferenceProperties?.deleted &&
      sectionReference.globalReferenceProperties?.withdrawn
    );
  }

  /**
   * True if the provided reference has been deleted.
   * Fragment type is necessary to display the correct tooltip in the HTML.
   */
  private static _referenceDeleted(sectionReference: SectionDisplayReference, fragmentType: FragmentType): boolean {
    if (fragmentType === FragmentType.DOCUMENT_REFERENCE) {
      return sectionReference.globalReferenceProperties?.deleted;
    } else if (fragmentType === FragmentType.INTERNAL_DOCUMENT_REFERENCE) {
      return sectionReference.internalReferenceProperties?.deleted;
    }
    return false;
  }
}
