import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatMenu} from '@angular/material/menu';
import {ActivatedRoute} from '@angular/router';
import {MchwExportRequest} from 'app/interfaces';
import {CarsAction} from 'app/permissions/types/permissions';
import {ExportService, ExportType} from 'app/services/export.service';
import {environment} from 'environments/environment';
import {Subscription} from 'rxjs';
import {DocumentFragment, SectionFragment} from '../../../../fragment/types';
import {SectionService} from '../../../../services/section.service';
import {RoleService} from '../../../../services/user/role.service';
import {CurrentView} from '../../../../view/current-view';
import {CurrentLocation, ViewService} from '../../../../view/view.service';
import {MchwExportSelectorComponent} from './mchw-export-selector/mchw-export-selector.component';
import {NaaExportSelectorComponent} from './naa-export-selector/naa-export-selector.component';
import {SectionCloneComponent} from './section-clone-selector/section-clone.component';
import {VersionCreatorComponent} from './version-creator/version-creator.component';

@Component({
  selector: 'cars-document-overview-section',
  templateUrl: './document-overview-section.component.html',
  styleUrls: ['./document-overview-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentOverviewSectionComponent implements OnInit, OnDestroy {
  // These are the locations that mark the Document Overview section as active/highlighted within the section-list sidebar
  private static DOCUMENT_OVERVIEW_LOCATIONS = [
    CurrentLocation.WSR_SUBJECT_TOPIC,
    CurrentLocation.CHANGE_SUMMARY,
    CurrentLocation.DOCUMENT_OVERVIEW,
  ];

  @Input() public currentView: CurrentView = null;
  @Input() public document: DocumentFragment = null;

  @ViewChild('documentMenu') public documentMenu: MatMenu;
  @ViewChild('pdfMenu') public pdfMenu: MatMenu;

  public readonly tooltipDelay: number = environment.tooltipDelay;
  public readonly CarsAction: typeof CarsAction = CarsAction;
  public readonly ExportType: typeof ExportType = ExportType;

  public documentVersionsRoute: string;
  public changeSummaryRoute: string;
  public wsrSubjectTopicRoute: string;
  public documentOverviewRoute: string;
  public selected: boolean = false;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _roleService: RoleService,
    private _exportService: ExportService,
    private _sectionService: SectionService,
    private _viewService: ViewService,
    private _dialog: MatDialog,
    private _route: ActivatedRoute
  ) {}

  public ngOnInit() {
    const rootDocumentPath: string = '/documents/' + this._route.snapshot.params['document_id'];
    this.documentVersionsRoute = rootDocumentPath + '/versions';
    this.documentOverviewRoute = '/' + this._route.snapshot.url.map((seg) => seg.path).join('/');
    this.changeSummaryRoute = this.documentOverviewRoute + '/change-summary';
    this.wsrSubjectTopicRoute = this.documentOverviewRoute + '/wsr-subject-topic';

    this._subscriptions.push(
      this._viewService.onCurrentViewChange((currentView: CurrentView) => {
        if (this.currentView.isChangelog() || this.currentView.isChangelogMarkup()) {
          this.documentOverviewRoute = rootDocumentPath;
        }
      }),
      this._sectionService.onSelection((_section: SectionFragment) => {
        this._setSelected();
      }),
      this._viewService.onLocationChange((_location: CurrentLocation) => {
        this._setSelected();
      })
    );
  }

  private _setSelected(): void {
    this.selected =
      !this._sectionService.getSelected() &&
      DocumentOverviewSectionComponent.DOCUMENT_OVERVIEW_LOCATIONS.includes(this._viewService.getLocation());
  }

  public ngOnDestroy() {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  public focusOverview(): void {
    document.getElementById('document-information')?.focus();
  }

  public collapseSidebar(): void {
    this._sectionService.setSelected(null);
  }

  public handleExportFile(exportType: ExportType): void {
    if (!this.document.documentData.naaRelationships || !this.document.documentData.naaRelationships.enabled) {
      this.export(exportType);
      return;
    }

    this._showNaaExportSelector(exportType);
  }

  public openMchwExportModal(exportType: ExportType): void {
    this._dialog
      .open(MchwExportSelectorComponent, {
        data: {fragmentId: this.document.id.value, versionTag: this.currentView.versionTag, exportType},
      })
      .afterClosed()
      .subscribe((result: MchwExportRequest) => {
        if (result) {
          this.handleExportMchwZip(result);
        }
      });
  }

  /**
   * Triggers the generation of an MCHW document zip
   */
  public handleExportMchwZip(mchwExportRequest: MchwExportRequest): void {
    this._exportService.generateMchwZip(this.document, mchwExportRequest, this.currentView.versionTag);
  }

  /**
   * If a document does have NAAs, then we need to present the choice to the user.
   *
   * @param exportType Type of document to export
   */
  private _showNaaExportSelector(exportType: ExportType): void {
    this._dialog
      .open(NaaExportSelectorComponent)
      .afterClosed()
      .subscribe((generateWithNAAs: boolean) => {
        if (generateWithNAAs !== null) {
          this.export(exportType, false, generateWithNAAs);
        }
      });
  }

  /**
   * Show the version creator
   *
   * @param template Show creator with create template
   */
  public showVersionCreator(template: boolean = false): void {
    this._dialog.open(VersionCreatorComponent, {
      ariaLabel: 'Create new version dialog',
      width: '350px',
      data: {document: this.document, template},
    });
  }

  public showSectionClone(): void {
    SectionCloneComponent.open(this._dialog);
  }

  public export(exportType: ExportType, forPreview: boolean = false, withNaas: boolean = false): void {
    this._exportService.generate(this.document, exportType, this.currentView.versionTag, forPreview, withNaas);
  }
}
