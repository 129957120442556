import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatListModule} from '@angular/material/list';
import {RouterModule} from '@angular/router';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {WsrReferenceSectionListItemComponent} from './wsr-reference-section-list-item.component';

@NgModule({
  imports: [CommonModule, RouterModule, MatListModule, AltAccessibilityModule, CarsPermissionPipesModule],
  declarations: [WsrReferenceSectionListItemComponent],
  exports: [WsrReferenceSectionListItemComponent],
})
export class WsrReferenceSectionListItemModule {}
