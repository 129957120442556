import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DocumentFragment, Fragment, FragmentType, SectionFragment, SectionType} from 'app/fragment/types';
import {SectionGroupFragment} from 'app/fragment/types/section-group-fragment';
import {CarsAction} from 'app/permissions/types/permissions';
import {WsrReferenceSectionResolver} from 'app/routing/resolvers/wsr-reference-section-resolver.service';
import {FragmentService} from 'app/services/fragment.service';
import {SectionService} from 'app/services/section.service';
import {CurrentLocation, ViewService} from 'app/view/view.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cars-wsr-reference-section-list-item',
  templateUrl: './wsr-reference-section-list-item.component.html',
  styleUrls: ['./wsr-reference-section-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WsrReferenceSectionListItemComponent implements OnInit, OnDestroy {
  @Input() public document: DocumentFragment = null;

  public readonly CarsAction: typeof CarsAction = CarsAction;
  public readonly sectionTitle: string = WsrReferenceSectionResolver.WSR_REFERENCE_SECTION_TITLE;
  public wsrReferenceSectionRoute: string;
  public selected: boolean = false;
  public sectionIndex: string;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _sectionService: SectionService,
    private _fragmentService: FragmentService,
    private _viewService: ViewService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.wsrReferenceSectionRoute = '/' + this._route.snapshot.url.map((seg) => seg.path).join('/') + '/wsr-references';
    this._subscriptions.push(
      this._sectionService.onSelection((_section: SectionFragment) => {
        this._setSelected();
      }),
      this._fragmentService.onUpdate((_fragment: Fragment) => {
        this._calculateSectionIndex();
      }, this._sectionFragmentPredicate.bind(this)),
      this._fragmentService.onCreate((_fragment: Fragment) => {
        this._calculateSectionIndex();
      }, this._sectionFragmentPredicate.bind(this)),
      this._fragmentService.onDelete((_fragment: Fragment) => {
        this._calculateSectionIndex();
      }, this._sectionFragmentPredicate.bind(this))
    );

    this._calculateSectionIndex();
  }

  public ngOnDestroy() {
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  private _sectionFragmentPredicate(fragment: Fragment): boolean {
    return (
      this.document.id.equals(fragment.documentId) && fragment.is(FragmentType.SECTION, FragmentType.SECTION_GROUP)
    );
  }

  private _setSelected(): void {
    this.selected = !this._sectionService.getSelected() && this._viewService.getLocation() === CurrentLocation.PAD;
    this._cdr.markForCheck();
  }

  private _calculateSectionIndex(): void {
    const sectionIndex =
      this.document.children.filter((child) => {
        if (child.type === FragmentType.SECTION_GROUP) {
          return !(child as SectionGroupFragment).deleted;
        }
        return (
          !(child as SectionFragment).deleted &&
          (child as SectionFragment).sectionType !== SectionType.DOCUMENT_INFORMATION
        );
      }).length + 1;

    this.sectionIndex = `${sectionIndex}.`;
    this._cdr.markForCheck();
  }

  public focusOverview(): void {
    document.getElementById('wsr-references')?.focus();
  }
}
